
import routerStore from './routerStore'
import authStore from './authStore'
import cartStore from './cartStore'
import toastStore from './toastStore'
import productStore from './productStore'

export default{
    authStore,
    routerStore,
    cartStore,
    toastStore,
    productStore
}
