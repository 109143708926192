<template>
    <div>
        <div class="card">
            <div class="product">
                <div @click="route(`/product-details/${product.id}`)" class="product-img">
                    <img class="img-fluid"   :src="`${apiDomain}/img/products/`+image" alt="">
                </div>
                <div @click="route(`/product-details/${product.id}`)" style="position:relative;height:60px;max-height: 60px;max-width:34vw">
                    <div style="text-align:center;color:#FA6C38">
                        <b>
                            <p class="product-details">
                                {{product.name}}
                            </p>
                        </b>
                    </div>
                    <div style="text-align:center;color:#717070">
                        GHS {{product.price}}
                    </div>
                </div>
                <div>
                    <!-- <center>
                        <button @click="addToCart(product)" class="btn btn-danger">
                            add to cart
                        </button>
                    </center> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {convertToArray} from '../../services/axios.js'
    export default {
        props: {
            product: {
                type: Object,
                required: true,

            },
        },

        data() {
            return {
                apiDomain: process.env.VUE_APP_API_DOMAIN,
                image: '',
            }
        },
        
        mounted() {
                var image = ""
                image  = this.product.image
                this.product.image = JSON.parse(image)
                this.image = this.product.image[0]
        },

        methods: {
            addToCart(index){
                this.$store.dispatch('addCartItem',index)
            },
            route(path) {
                this.$router.push({path})
            }
            
        },
       
    }
</script>

<style lang="scss" scoped>
.card {
    padding-top: 10px;
}
.img-fluid {
    max-height: 100%;
    max-width: 100%;
}
</style>