import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import {routes} from './routes'

Vue.use(Router)
export const router = new Router({
    linkExactActiveClass: 'nav-active',
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
      let position = { x: 0, y: 0 }
      // Keep scroll position when using browser buttons
      if (savedPosition) {
        position = savedPosition
      }
  
      // Workaround for transitions scrolling to the top of the page
      // However, there are still some problems being fixed by the vue team
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(position)
        })
      })
    },
})

router.beforeEach( (to, from, next ) => {
  
  document.title = `${process.env.VUE_APP_NAME} | ${to.meta.title}` || process.env.VUE_APP_NAME;
  if(JSON.parse(window.localStorage.getItem('cartItems'))){
    const cart = JSON.parse(window.localStorage.getItem('cartItems'))
    store.dispatch('addCartFromStorage',cart)
  }
  
  if(JSON.parse(window.localStorage.getItem('authUser'))) {
    const authUser = JSON.parse(window.localStorage.getItem('authUser'))
    //authUser && authUser.access_token
    if( authUser ){
      store.dispatch('setUserObject',authUser)
    }
  }

  if(to.meta.requiresAuth){
    if(JSON.parse(window.localStorage.getItem('authUser'))){
      const authUser = JSON.parse(window.localStorage.getItem('authUser'))
      if(authUser && authUser.sz_email){
        store.dispatch('setUserObject',authUser)
        next()
      }
    }else {
      next({name:'Login'})
      }
    }else{
  
      next()
    }


})