import { axios } from '../axios'

export default {
    /**
    * 
    * get all programs from the api
    */
    index(model) {
        return axios.get('api/'+model)
            .then(({data}) => {

                return Promise.resolve(data)
            })
            .catch((err) => {
                return Promise.reject(err)
        })
    },


    /**
     * 
     * @param {String} model 
     * @param {Integer} id 
     */
    show(model,id) {
        return axios.get('api/'+model+'/'+id)
            .then(({data}) => {

                return Promise.resolve(data)
            })
            .catch((err) => {
                return Promise.reject(err)
        })
    },

    /**
     * 
     * @param {String} model 
     * @param {Object} payload 
     */
    add(model,payload) {
        return axios.post('api/'+model,payload)
            .then( ({data}) => {
                return Promise.resolve(data)
            })
            .catch( (err) => {
                return Promise.reject(err)

            })
    },

    delete(model,id) {
        return axios.delete('api/'+model+'/'+id)
            .then(({data}) => {
                    
                return Promise.resolve(data)
            })
            .catch((err) => {
                return Promise.reject(err)
        })
    },

    update(model,payload,id) {
        return axios.put('api/'+model+'/'+id,payload)
            .then( ({data}) => {
                return Promise.resolve(data)
            })
            .catch( (err) => {
                return Promise.reject(err)

            })
    },


    



}