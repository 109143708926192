import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
//import './registerServiceWorker'
import plugins  from './utils/plugins'
//import VueAnalytics from 'vue-analytics';
//import VueFacebookPixel from 'vue-analytics-facebook-pixel';


Vue.use(plugins)
//Vue.use(VueFacebookPixel)
Vue.config.productionTip = false
/* 
Vue.use(VueAnalytics, {
  id: 'UA-164393557-1',
  router
}); */

/**
 * Init facebook tracking pixel
 * @param  {String} appId
 * @param  {object} [data={}]
 *
 * 
Vue.analytics.fbq.init(723034181568020, {
  em: 'info@aljani.com'
})*/




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
