/* eslint-disable eol-last */
import Axios from 'axios'
import { getHeader, url_exceptions} from './token'

const instance  =  Axios.create({
    baseURL: process.env.VUE_APP_API_DOMAIN, 
    responseType: 'json',
    timeout: 20000
})

const axios = instance;


axios.interceptors.request.use(function(config) {
    var header = getHeader()
    url_exceptions.forEach(url => {
        if(url !== config.url) {
            config.headers.Authorization  = `${header.Authorization}`;
            config.headers.Accept  = "application/json"
           
        }
    });
    return config
})

axios.interceptors.response.use((response) => {
    return response;
}, function (error) {
    // Do something with response error
    /* if (error.response.status === 401) {
        console.log('unauthorized, logging out ...');
        auth.logout();
        router.replace('/auth/login');
    } */
    return Promise.reject(error.response);
});

const convertToArray = (data) => {
    let params_arr = [];
    data.forEach(el => {
        params_arr.push(JSON.parse(el));
    });
    return [...params_arr]
}



const convertToObject = (data) => {
    let params_arr = [];
    data.forEach(el => {
        params_arr.push(JSON.parse(el));
    });
    return {...params_arr[0]}
}


export {
    axios, convertToArray, convertToObject
}