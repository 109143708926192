import { mainRoutes } from './mainRoutes'
export const routes = [
    /* {
        path: '/',
        name: 'main',
        component: () => import('../layout/MainLayout.vue'),
        children: [...mainRoutes],
        meta: {
            requiresAuth: false
        },
    }, */
    {
        path: '/',
        name: 'welcome',
        component: () => import(/* webpackChunkName: "welcome.chunk" */"@/views/welcome.vue"),
        meta: {
            requiresAuth: false,
            title: "welcome"
        }
        
    },
    {
        path: "/panel",
        name: "panel",
        component: () => import(/* webpackChunkName: "mainlayout.chunk" */'@/layouts/MainLayout'),
        children: [...mainRoutes],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/auth',
        component: () => import('@/layouts/AuthLayout.vue'),
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import(/* webpackChunkName: "login.chunk" */'@/views/Auth/login.vue'),
                meta: {
                    login: true,
                    title: "Login"
                }
            },
            /* {
                path: 'signup',
                name: 'Signup',
                component: () => import('@/views/Auth/register.vue'),
                meta:{
                    login:true,        
                }
            }, */
        ]
    },
   
    {
        path: '/404',
        name: 'Page not found',
        component:() => import(/* webpackChunkName: "404.chunk" */'../views/errors/404')
    },  
    {
        path: '*',
        redirect: '/404'
    },
    
]