import Crud from '../../services/main/CRUD'
const state ={
   product: {},
   selectedImage: {
       image: '',
       index: 0,
   },
}
const getters = {
    getProduct: state => {
        return state.product
    },

    
}
const mutations = {
   
    SET_PRODUCT(state, payload) {
        
        state.product = payload ? payload : ''
        state.selectedImage.image = payload.image[0]
        state.selectedImage.index = 0
    },
    EMPTY_PRODUCT(state) {
        state.product = {}

    },

    SET_CURRENT_IMAGE(state, index) {
        state.selectedImage.image = state.product.image[index]
        state.selectedImage.index = index

    }
}
const actions = {
    getProduct:({commit},product_id) => {
        commit('EMPTY_PRODUCT')
        Crud.show('products',product_id).then((response) => {
            var product = response.data
            product.image = JSON.parse(product.image)
            product.color = JSON.parse(product.color)
            commit('SET_PRODUCT',product)

            return Promise.resolve(product)
        }).catch((error) => {
            return Promise.reject(error)
        })
        
        
    },

    setCurrentImage: ({commit}, index) => {
        commit('SET_CURRENT_IMAGE', index)
    },

    setFetching: ({commit},status) => {
        commit('SET_FETCHING',status)
    },

    setRouteHeader: ({commit}, payload) => {
        commit('SET_ROUTE_HEADER',payload)
    }
}
export default{
    state,getters,mutations,actions
}