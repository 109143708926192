const state = {
    authUser: null,
    isFirstTime: 0,
}
const mutations = {
    SET_AUTH_USER(state,payload) {
        state.authUser = payload
    },
    UNSET_AUTH_USER(state) {
        state.authUser = ''
    }
}
const getters = {
    getAuthUser: state => {
        return state.authUser;
    },
    getFirstName: state => {
        var fullName = state.authUser.name
        return fullName.split(" ").splice(0,1).join(" ")
    }
}
const actions = {
    setUserObject:({commit},payload) => {
        commit('SET_AUTH_USER',payload)
    },
    setAuthUser: ({commit}, payload) => {
        commit('SET_AUTH_USER',payload)
        window.localStorage.setItem('authUser',JSON.stringify(state.authUser))

    },
    unsetAuthUser: ({commit,dispatch}) => {
        commit('UNSET_AUTH_USER')
        window.localStorage.removeItem('authUser')
        //dispatch('success','You have logged out',{root:true})
    },
}

export default {
    state, mutations, getters, actions
}