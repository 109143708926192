const state ={
    //general loading
    loading:false,

    //custom loading
    fetching: false,
    error: false,
    header: '',
}
const getters = {
    getLoading: state => {
        return state.loading
    },

    getRouteHeader: state => {
        return state.header
    }
}
const mutations = {
    SET_LOADING(state,status){
        state.loading = status
    },

    SET_FETCHING(state,status){
        state.fetching = status
    },

    SET_ROUTE_HEADER(state, payload) {
        
        state.header = payload ? payload : ''
    }
}
const actions = {
    setLoading:({commit},status) => {
        commit('SET_LOADING',status)
    },

    setFetching: ({commit},status) => {
        commit('SET_FETCHING',status)
    },

    setRouteHeader: ({commit}, payload) => {
        commit('SET_ROUTE_HEADER',payload)
    }
}
export default{
    state,getters,mutations,actions
}