import  GlobalComponents  from './modules/GlobalComponents'
import  filters  from '../filters'
import Vue from 'vue'
import VeeValidate from 'vee-validate'
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.css'

const plugins = {
    install(Vue,options) {
        Vue.config.productionTip = true
        GlobalComponents()
        Vue.use(filters)
        Vue.use(VeeValidate)
        Vue.use(VueIziToast)
    }
}
export default plugins

