export const mainRoutes = [
    {
        path: 'dashboard',
        name: "Dashboard",
        component: () => import(/* webpackChunkName: "daashboard.chunk" */"../views/Panel/index.vue"),
        meta: {
            title: "Dashboard"
        }
    },
    {
        path: 'categories',
        name: 'category',
        component: () => import(/* webpackChunkName: "categories_index.chunk" */"../views/Categories/index.vue"),
        meta: {
            title: "Categories"
        }
    },
    {
        path: 'weeklybanner',
        name: 'weeklybanner',
        component: () => import(/* webpackChunkName: "categories_index.chunk" */"../views/Banner/index.vue"),
        meta: {
            title: "Weekly banner"
        }
    },
    {
        path: 'categories/:id/view',
        name: "View categories",
        component: () => import(/* webpackChunkName: "categories_view.chunk" */'../views/Categories/view.vue'),
        meta: {
            title: "View Category"
        },
    },
    {
        path: 'categories/:id/edit',
        name: "Edit categories",
        component: () => import(/* webpackChunkName: "categories_edit.chunk" */'../views/Categories/edit.vue'),
        meta: {
            title: "Edit Category"
        },
    },
    {
        path: 'categories/add',
        name: "Add Categories",
        component: () => import(/* webpackChunkName: "categories_add.chunk" */'../views/Categories/add.vue'),
        meta: {
            title: "Add Categories"
        },
    },
    {
        path: 'creative-categories',
        name: 'All Creative Categories',
        component: () => import(/* webpackChunkName: "creative_categories.chunk" */'../views/Categories/creative.vue'),
    },
    {
        path: 'photos',
        name: "My photos",
        component: () => import(/* webpackChunkName: "product_index.chunk" */'../views/Photos/all.vue'),
        meta: {
            title: "Product"
        },
    },
    {
        path: 'photos/:status',
        name: "status photos",
        component: () => import(/* webpackChunkName: "active.chunk" */'../views/Photos/status.vue'),
        meta: {
            title: "Product Status"
        },
    },
    {
        path: 'photos/:id/view',
        name: "View photos",
        component: () => import(/* webpackChunkName: "photos_view.chunk" */'../views/Photos/view.vue'),
        meta: {
            title: "View Product"
        },
    },
    {
        path: 'creatives',
        name: "Creatives",
        component: () => import(/* webpackChunkName: "all creatives.chunk" */'../views/Creatives/all.vue'),
        meta: {
            title: "All Creatives"
        },
    },
    {
        path: 'creatives/:id/view',
        name: "View Creatives",
        component: () => import(/* webpackChunkName: "all view_creatives.chunk" */'../views/Creatives/view.vue'),
        meta: {
            title: "View Creatives"
        },
    },
    {
        path: 'users',
        name: "Users",
        component: () => import(/* webpackChunkName: "all users.chunk" */'../views/Users/all.vue'),
        meta: {
            title: "All Users"
        },
    },
    {
        path: 'users/:id/view',
        name: "View User",
        component: () => import(/* webpackChunkName: "all view_creatives.chunk" */'../views/Users/view.vue'),
        meta: {
            title: "View User"
        },
    },
    {
        path: 'orders/:id',
        name: "View Order Items",
        component: () => import(/* webpackChunkName: "all view_creatives.chunk" */'../views/Orders/orderItems.vue'),
        meta: {
            title: "Order Items"
        },
    },
    
    /* {
        path: 'project',
        name: "Projects",
        component: () => import("../views/Projects/index.vue")
    } */
]