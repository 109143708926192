<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
#app {
  overflow-y: hidden;
  overflow-x: hidden;
}
img {
  cursor: pointer;
}
</style>


