<template>
    <div class="page-container">
        <div class="container">
            <center>
                <img height="150px" width="150px" src="/images/loader/06-loader_telega.gif" alt="">
                <div>
                    loading...
                </div>
            </center>
            
        </div>


    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    
</style>